import { useState, useContext } from 'react';

import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';

const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });

const MintForm = () => {  
  const [enteredName, setEnteredName] = useState('');
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);

  const [enteredDescription, setEnteredDescription] = useState('');
  const [nameIsValid, setNameIsValid] = useState(true);

  const [capturedFileBuffer, setCapturedFileBuffer] = useState(null);
  const [fileIsValid, setFileIsValid] = useState(true);

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

  const enteredNameHandler = (event) => {
    setEnteredName(event.target.value);
  };

  const enteredDescriptionHandler = (event) => {
    setEnteredDescription(event.target.value);
  };
  
  const captureFile = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      setCapturedFileBuffer(Buffer(reader.result));     
    }
  };  
  
  const submissionHandler = (event) => {
    event.preventDefault();

    enteredName ? setNameIsValid(true) : setNameIsValid(false);
    enteredDescription ? setDescriptionIsValid(true) : setDescriptionIsValid(false);
    capturedFileBuffer ? setFileIsValid(true) : setFileIsValid(false);

    const formIsValid = enteredName && enteredDescription && capturedFileBuffer;

    // Upload file to IPFS and push to the blockchain
    const mintNFT = async() => {
      // Add file to the IPFS
      const fileAdded = await ipfs.add(capturedFileBuffer);
      if(!fileAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }

      const metadata = {
        title: "Asset Metadata",
        type: "object",
        properties: {
          name: {
            type: "string",
            description: enteredName
          },
          description: {
            type: "string",
            description: enteredDescription
          },
          image: {
            type: "string",
            description: fileAdded.path
          }
        }
      };

      const metadataAdded = await ipfs.add(JSON.stringify(metadata));
      if(!metadataAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }
      
      collectionCtx.contract.methods.safeMint(metadataAdded.path).send({ from: web3Ctx.account })
      .on('transactionHash', (hash) => {
        collectionCtx.setNftIsLoading(true);
      })
      .on('error', (e) =>{
        window.alert('Something went wrong when pushing to the blockchain');
        collectionCtx.setNftIsLoading(false);  
      })      
    };

    formIsValid && mintNFT();
  };

  const nameClass = nameIsValid? "form-control" : "form-control is-invalid";
  const descriptionClass = descriptionIsValid? "form-control" : "form-control is-invalid";
  const fileClass = fileIsValid? "form-control" : "form-control is-invalid";
  
  return(
    <><section className="page-title-area">
      <div className="container">
        <div className="row wow fadeInUp">
          <div className="col-lg-12">
            <div className="page-title">
              <h2 className="breadcrumb-title mb-10">Upload Nft</h2>
            
            </div>
          </div>
        </div>
      </div>
    </section><div className="upload-area pt-50 pb-90" data-background="">
        <div className="container">
          <div className="upload-wrapper mb-10">
            <form onSubmit={submissionHandler} className="upload-form">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row wow fadeInUp">
                    <div className="col-md-6">
                      <div className="single-input-unit">
                        <label for="art-name"> Name</label>
                        <input className={`${nameClass} mb-1`} type="text" value={enteredName}
              onChange={enteredNameHandler} placeholder=" Name" />
                        </div>
                    </div>
                   
                    <div className="col-md-6">
                      <div className="single-input-unit">
                        <label for="art-name"> Select File</label>
                        <input
              type='file'
              className={`${fileClass} mb-1`}
              onChange={captureFile} />

                        </div>
                    </div>
                    
                  
                    <div class="col-md-12">
                      <div class="single-input-unit">
                        <label for="art-desc">Item Description</label>
                        <textarea className={`${descriptionClass} mb-1`} value={enteredDescription}
              onChange={enteredDescriptionHandler} placeholder="Write Description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="upload-btn wow fadeInUp">
                    <button id="upload-btn" type="submit" className="fill-btn">Upload </button>
                   
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="row">
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      
   </>
  );
};

export default MintForm;