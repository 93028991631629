import { useContext  } from 'react';


import MintForm from './MintNFT/MintForm';
import NFTCollection from './NFTCollection/NFTCollection';
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import Spinner from '../Layout/Spinner';
//import logo from '../../img/logo2.PNG'

const Form = () => {
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  
  return(
    <>
    <section class="artworks-area pt-110 pb-100">
         <div class="container c-container-1">
         {!collectionCtx.nftIsLoading && <MintForm />}
              {collectionCtx.nftIsLoading && <Spinner />}
         </div>
         </section>
      
   </>
  );
};

export default Form;