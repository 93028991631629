import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from '../Content/About';

const Sidebar = () => {

    return (
    <>

    <div className="fix">
            <div className="menu2-side-bar-wrapper">
                <div className="menu2-side-bar">
                    <div className="side-info-content">
                        <div className="offset-widget offset-logo mb-25">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <div className="header-logo header1-logo">
                                        <a href="index.html" class="logo-bb"><img src="assets/img/logo/oction-logo.png"
                                            alt="logo-img" /></a>
                                        <a href="index.html" class="logo-bw"><img src="assets/img/logo/oction-logo-bw.png"
                                            alt="logo-img" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-menu main-menu1">
                      
                            <nav id="menu2-mobile-menu">
                            
                                <ul>
                                
                               
                                    <li><Link to="/" >Home</Link>  </li>
                                    <li><Link to="/explore" >Explore</Link>  </li>
                                 
                                </ul>
                            </nav>
                        </div>
                        <div className="menu2-mobile-menu fix">

                        </div>

                        <div className="menu2-sidebar-widget mt-35">
                            <div className="work-process-single pos-rel">
                                <div className="work-process-content">
                                    <div className="process-icon">
                                        <img src="assets/img/shape/list-icon-2.png" alt="img" />
                                    </div>
                                    <h4 className="process-title">
                                        <a href="javascript:void(0)">Create and sell your NFTs</a>
                                    </h4>
                                    <div className="work-process-btn">
                                    <Link to="/create-nft" className="fill-btn">Create Now</Link>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        
     
        <div className="offcanvas-overlay"></div><div className="offcanvas-overlay-white"></div><div className="fix">
                <div className="sidebar-category-filter-wrapper">
                    <div className="sidebar-category-filter">
                        <div className="filter-widget mb-20">
                            <div className="filter-widget-content">
                                <h3 className="filter-widget-title drop-btn">Category</h3>
                                <ul>
                                    <li>
                                        <div className="category-item">
                                            <a href="explore-arts.html">
                                                <img src="assets/img/svg-icon/021-star.svg" alt="icon-img" />
                                                All
                                                Items</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="category-item">
                                            <a href="explore-arts.html">
                                                <img src="assets/img/svg-icon/014-artwork.svg" alt="icon-img" />
                                                Creative
                                                Artworks</a>
                                        </div>
                                    </li>




                                    <li>
                                        <div className="category-item">
                                            <a href="explore-arts.html"><img src="assets/img/svg-icon/019-photography.svg"
                                                alt="icon-img" />
                                                Photography</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="category-item">
                                            <a href="explore-arts.html">
                                                <img src="assets/img/svg-icon/020-laughing.svg"
                                                    alt="icon-img" /> Cartoons</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="filter-widget mb-20">
                            <div className="filter-widget-content">
                                <h3 className="filter-widget-title drop-btn">Sale Type</h3>
                                <ul>
                                    <li>
                                        <div className="filter-list-item">
                                            <input className="oc-check-box" type="checkbox" id="s-t-1" />
                                            <label className="oc-check-label" for="s-t-1">Fixed</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="filter-list-item">
                                            <input className="oc-check-box" type="checkbox" id="s-t-2" />
                                            <label className="oc-check-label" for="s-t-2">Auction</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="filter-list-item">
                                            <input className="oc-check-box" type="checkbox" id="s-t-3" />
                                            <label className="oc-check-label" for="s-t-3">Bid</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="filter-widget">
                            <div className="filter-widget-content">
                                <h3 className="filter-widget-title drop-btn">Status</h3>
                                <ul>
                                    <li>
                                        <div className="filter-list-item">
                                            <input className="oc-check-box" type="checkbox" id="sta-1" />
                                            <label className="oc-check-label" for="sta-1">New Arrival</label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="filter-list-item">
                                            <input className="oc-check-box" type="checkbox" id="sta-2" />
                                            <label className="oc-check-label" for="sta-2">Featured</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div></>);
};
export default Sidebar;