import { useContext  } from 'react';
import { Link } from "react-router-dom";

//import MintForm from './MintNFT/MintForm';
import NFTCollection from './NFTCollection/NFTCollection';
//import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import Spinner from '../Layout/Spinner';
//import logo from '../../img/logo2.PNG'

const Main = () => {
  //const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  
  return(
    <>
    
      <div class="banner-area banner-area3 pos-rel pt-130">
         <div class="container c-container-1">
            <div class="row">
               <div class="col-xl-8 banner-3-column">
                  <div class="single-banner single-banner-3 banner-460 d-flex align-items-center pos-rel">
                     <div class="banner-bg">
                       <img src="assets/img/banner/banner-3-bg.jpg" alt="" /></div>
                     <div class="banner-bg-light">
                       <img src="assets/img/banner/banner-3-bg.jpg" alt="" /></div>
                     <div class="banner-content banner-content3 pt-0">
                        <h1 data-animation="fadeInUp" data-delay=".3s" class="mb-20 font-prata">
                           Discover Digital Artworks & Collect Best <span>NFTs</span></h1>
                        <p data-animation="fadeInUp" data-delay=".5s" class="mb-30">There is enough digital artworks
                           available online to help you put together.</p>
                        <div class="banner-btn" data-animation="fadeInUp" data-delay=".7s">
                           <a href="explore-arts.html" class="fill-btn">Explore Now</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 sidebar-art-list-column">
                  <div class="sidebar-art-list">
                     <div class="art-item-single sidebar-art-item">
                        <div class="art-item-wraper">
                           <div class="art-item-inner">
                              <div class="art-item-img pos-rel">
                                 <a href="art-details.html"><img src="assets/img/bids/oc-category-2-2.jpg"
                                       alt="art-img" /></a>
                              </div>
                              <div class="art-item-content pos-rel">
                                 <h4 class="art-name"><a href="art-details.html">Color Abstract Cube</a></h4>
                                 <div class="artist">
                                    <div class="profile-img pos-rel">
                                       <a href="creator-profile.html"><img src="assets/img/profile/profile5.jpg"
                                             alt="profile-img" /></a>
                                       <div class="profile-verification verified">
                                          <i class="fas fa-check"></i>
                                       </div>
                                    </div>
                                    <div class="artist-id">@jarin_mock</div>
                                 </div>
                                 <div class="art-meta-info">
                                    <div class="art-meta-item">
                                       <div class="art-meta-type">Current Bid</div>
                                       <div class="art-price">24.47 ETH</div>
                                    </div>
                                    <div class="art-activity-btn">
                                       <a href="#" class="place-bid">Place Bid</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="art-item-single sidebar-art-item">
                        <div class="art-item-wraper">
                           <div class="art-item-inner">
                              <div class="art-item-img pos-rel">
                                 <a href="art-details.html"><img src="assets/img/bids/oc-category-1.jpg"
                                       alt="art-img" /></a>
                              </div>
                              <div class="art-item-content pos-rel">
                                 <h4 class="art-name"><a href="art-details.html">3D Crypto Artwork</a></h4>
                                 <div class="artist">
                                    <div class="profile-img pos-rel">
                                       <a href="creator-profile.html"><img src="assets/img/profile/profile6.jpg"
                                             alt="profile-img" /></a>
                                       <div class="profile-verification verified">
                                          <i class="fas fa-check"></i>
                                       </div>
                                    </div>
                                    <div class="artist-id">@chess.62</div>
                                 </div>
                                 <div class="art-meta-info">
                                    <div class="art-meta-item">
                                       <div class="art-meta-type">Current Bid</div>
                                       <div class="art-price">23.84 ETH</div>
                                    </div>
                                    <div class="art-activity-btn">
                                       <a href="#" class="place-bid">Place Bid</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="art-item-single sidebar-art-item">
                        <div class="art-item-wraper">
                           <div class="art-item-inner">
                              <div class="art-item-img pos-rel">
                                 <a href="art-details.html"><img src="assets/img/bids/oc-category-3.jpg"
                                       alt="art-img" /></a>
                              </div>
                              <div class="art-item-content pos-rel">
                                 <h4 class="art-name"><a href="art-details.html">Watch Looper</a></h4>
                                 <div class="artist">
                                    <div class="profile-img pos-rel">
                                       <a href="creator-profile.html"><img src="assets/img/profile/profile7.jpg"
                                             alt="profile-img" /></a>
                                       <div class="profile-verification verified">
                                          <i class="fas fa-check"></i>
                                       </div>
                                    </div>
                                    <div class="artist-id">@stephens</div>
                                 </div>
                                 <div class="art-meta-info">
                                    <div class="art-meta-item">
                                       <div class="art-meta-type">Current Bid</div>
                                       <div class="art-price">32.84 ETH</div>
                                    </div>
                                    <div class="art-activity-btn">
                                       <a href="#" class="place-bid">Place Bid</a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <section class="artworks-area pt-110 pb-100">
         <div class="container c-container-1">
            <div class="row wow fadeInUp">
               <div class="col-lg-8">
                  <div class="section-title1">
                     <h2 class="section-main-title1 mb-40">Explore Artworks</h2>
                  </div>
               </div>
               <div class="col-lg-4">
               </div>
            </div>
            <div class="row wow fadeInUp home-3-artworks">

            {!marketplaceCtx.mktIsLoading && <NFTCollection />}
        {marketplaceCtx.mktIsLoading && <Spinner />}

               
            </div>
            <div class="artwork-btn text-center pt-20 pb-30">
            <Link to="/explore" className="fill-btn" >Explore</Link>
             
            </div>
         </div>
      </section>
   </>
  );
};

export default Main;