import { useContext, useState } from 'react';

import Web3Context from '../../store/web3-context';
import MarketplaceContext from '../../store/marketplace-context';
import web3 from '../../connection/web3';
import { formatPrice } from '../../helpers/utils';

const Navbar = () => {
  const [fundsLoading, setFundsLoading] = useState(false);
  
  const web3Ctx = useContext(Web3Context);
  const marketplaceCtx = useContext(MarketplaceContext);
  
  const connectWalletHandler = async() => {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }

    // Load accounts
    web3Ctx.loadAccount(web3);
  };
console.log('account');
console.log(web3Ctx);
  const claimFundsHandler = () => {
    marketplaceCtx.contract.methods.claimFunds().send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {
      setFundsLoading(true);
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setFundsLoading(false);
    });
  };

  // Event ClaimFunds subscription 
  marketplaceCtx.contract.events.ClaimFunds()
  .on('data', (event) => {
    marketplaceCtx.loadUserFunds(marketplaceCtx.contract, web3Ctx.account);
    setFundsLoading(false);
  })
  .on('error', (error) => {
    console.log(error);
  });

  let etherscanUrl;

  if(web3Ctx.networkId === 3) {
    etherscanUrl = 'https://ropsten.etherscan.io'
  } else if(web3Ctx.networkId === 4) {
    etherscanUrl = 'https://rinkeby.etherscan.io'
  } else if(web3Ctx.networkId === 5) {
    etherscanUrl = 'https://goerli.etherscan.io'
  } else {
    etherscanUrl = 'https://etherscan.io'
  }
  
  return (
    <><header className="header2">
      <div className="header-main header-main2">
        <div className="container c-container-1">
          <div className="header-main2-content">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-7 col-7">
                <div className="header-main-left">
                  <div className="menu-bar mr-20 d-xxl-none">
                    <a className="side-toggle" href="javascript:void(0)">
                      <div className="bar-icon left-bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </a>
                  </div>
                  <form action="#" className="filter-search-input header-search d-none d-md-inline-block">
                    <input type="text" placeholder="Search keyword" />
                    <button><i className="fal fa-search"></i></button>
                  </form>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-5">
                <div className="header-main-right">

                  <div className="header-btn ml-20 d-none d-xxl-inline-block">
                  {web3Ctx.account && 
            <a 
              className="fill-btn" 
              href={`${etherscanUrl}/address/${web3Ctx.account}`}
              target="blank"
              rel="noopener noreferrer"
            >
              {web3Ctx.account}
            </a>}
            {!web3Ctx.account && 
            <button 
              type="button" 
              className="fill-btn" 
              onClick={connectWalletHandler} 
            > 
              Connect wallet
            </button>}
                   
                  </div>
                  <div className="profile-item profile-item-header ml-20 d-none d-md-inline-block pos-rel">
                    <div className="profile-img pos-rel">
                      <div className="profile-action">
                        <ul>
                          <li><a href="javascript:void(0)"><i
                            class="fal fa-user"></i>Profile</a>
                          </li>
                          <li><a href="javascript:void(0)"><i class="fal fa-sign-out"></i>Logout</a></li>
                        </ul>
                      </div>
                      <img src="assets/img/profile/profile4.jpg" alt="profile-img" />
                        <div className="profile-verification verified">
                          <i className="fas fa-check"></i>
                        </div>
                      </div>

                  </div>
                  <div className="product-filter-btn ml-20 d-xxl-none">
                    <i className="flaticon-filter"></i>
                  </div>
               
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </header></>
  );  
};

export default Navbar;