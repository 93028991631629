import { useContext, useRef, createRef } from 'react';

import web3 from '../../../connection/web3';
import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import MarketplaceContext from '../../../store/marketplace-context';
import { formatPrice } from '../../../helpers/utils';
import eth from '../../../img/eth.png';

const NFTCollection = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);

  const priceRefs = useRef([]);
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length).fill().map((_, i) => priceRefs.current[i] || createRef());
  }
  
  const makeOfferHandler = (event, id, key) => {
    event.preventDefault();

    const enteredPrice = web3.utils.toWei(priceRefs.current[key].current.value, 'ether');

    collectionCtx.contract.methods.approve(marketplaceCtx.contract.options.address, id).send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {
      marketplaceCtx.setMktIsLoading(true);
    })
    .on('receipt', (receipt) => {      
      marketplaceCtx.contract.methods.makeOffer(id, enteredPrice).send({ from: web3Ctx.account })
      .on('error', (error) => {
        window.alert('Something went wrong when pushing to the blockchain');
        marketplaceCtx.setMktIsLoading(false);
      }); 
    });
  };
  
  const buyHandler = (event) => {    
    const buyIndex = parseInt(event.target.value);      
    marketplaceCtx.contract.methods.fillOffer(marketplaceCtx.offers[buyIndex].offerId).send({ from: web3Ctx.account, value: marketplaceCtx.offers[buyIndex].price })
    .on('transactionHash', (hash) => {
      marketplaceCtx.setMktIsLoading(true);
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      marketplaceCtx.setMktIsLoading(false);
    });            
  };

  const cancelHandler = (event) => {    
    const cancelIndex = parseInt(event.target.value);
    marketplaceCtx.contract.methods.cancelOffer(marketplaceCtx.offers[cancelIndex].offerId).send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {
      marketplaceCtx.setMktIsLoading(true);
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      marketplaceCtx.setMktIsLoading(false);
    });    
  };
 
  return(
    <div className="row text-center">
      {collectionCtx.collection.map((NFT, key) => {
        const index = marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id === NFT.id) : -1;
        const owner = index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
        const price = index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null;

        return(

         <div key={key} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <div className="art-item-single mb-30">
              <div className="art-item-wraper">
                <div className="art-item-inner">
                  <div className="art-item-img pos-rel">
                    
                   
                    <a href="#" class="place-bid">Place Bid</a>
                    <a href="#">
                      <img src={`https://ipfs.infura.io/ipfs/${NFT.img}`} alt="art-img" /></a>
                  </div>
                  <div className="art-item-content pos-rel">
                    
                    <div className="artist">
                      <div className="profile-img pos-rel">
                        <a href="creator-profile.html">
                          <img src="assets/img/profile/profile1.jpg"
                            alt="profile-img" /></a>
                        <div className="profile-verification verified">
                          <i className="fas fa-check"></i>
                        </div>
                      </div>
                      <div className="artist-id">@russell</div>
                    </div>
                    <h4 className="art-name"><a href="#">{NFT.title}</a></h4>
                    <div className="art-meta-info">
                      <div className="art-meta-item">
                      {index !== -1 ?
                owner !== web3Ctx.account ?
                  <div className="row">
                    <div className="d-grid gap-2 col-5 mx-auto">
                      <button onClick={buyHandler} value={index} className="btn btn-success">BUY</button>
                    </div>
                    <div className="col-7 d-flex justify-content-end">
                      <img src={eth} width="25" height="25" className="align-center float-start" alt="price icon"></img>
                      <p className="text-start"><b>{`${price}`}</b></p>
                    </div>
                  </div> :
                  <div className="row">
                    <div className="d-grid gap-2 col-5 mx-auto">
                      <button onClick={cancelHandler} value={index} className="btn btn-danger">CANCEL</button>
                    </div>
                    <div className="col-7 d-flex justify-content-end">
                      <img src={eth} width="25" height="25" className="align-center float-start" alt="price icon"></img>
                      <p className="text-start"><b>{`${price}`}</b></p>
                    </div>
                  </div> :
                owner === web3Ctx.account ?
                  <form className="row g-2" onSubmit={(e) => makeOfferHandler(e, NFT.id, key)}>
                    <div className="col-5 d-grid gap-2">
                      <button type="submit" className="btn btn-secondary">OFFER</button>
                    </div>
                    <div className="col-7">
                      <input
                        type="number"
                        step="0.0001"
                        placeholder="ETH..."
                        className="form-control"
                        ref={priceRefs.current[key]} />
                    </div>
                  </form> :
                  <p><br /></p>}
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
        );
      })}
    </div>
  );
};

export default NFTCollection;