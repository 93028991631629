import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';

import Web3Provider from './store/Web3Provider';
import CollectionProvider from './store/CollectionProvider';
import MarketplaceProvider from './store/MarketplaceProvider';
import App from './App';
import About from './components/Content/About';
import Explore from './components/Content/Explore';


ReactDOM.render(
  
  <Web3Provider>
    <CollectionProvider>
      <MarketplaceProvider>
      <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="explore" element={<Explore />} />
      <Route path="create-nft" element={<About />} />
    </Routes>
  </BrowserRouter>
      </MarketplaceProvider>
    </CollectionProvider>
  </Web3Provider>
 , 
  document.getElementById('root')
);