import { useContext  } from 'react';


//import MintForm from './MintNFT/MintForm';
import NFTCollection from './NFTCollection/NFTCollection';
//import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import Spinner from '../Layout/Spinner';
//import Explore from './Explore';
//import logo from '../../img/logo2.PNG'

const Explorehere = () => {
  //const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  
  return(
    <>
    <br/><br/><br/><br/>
    <section class="page-title-area">
         <div class="container">
            <div class="row wow fadeInUp">
               <div class="col-lg-12">
                  <div class="page-title">
                     <h2 class="breadcrumb-title mb-10">Explore Artworks</h2>
                 
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="artworks-area pt-110 pb-100">
         <div class="container c-container-1">
           
            <div class="row wow fadeInUp home-3-artworks">

            {!marketplaceCtx.mktIsLoading && <NFTCollection />}
        {marketplaceCtx.mktIsLoading && <Spinner />}

               
            </div>
           
         </div>
      </section>
   </>
  );
};

export default Explorehere;